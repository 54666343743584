import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { setupInterceptors } from "@/network/Config/interceptors";
import { baseURL } from "@/utils/constants";

const client: AxiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 15000,
  headers: {
    "Content-Type": "application/json",
  },
});

// Setup interceptors
setupInterceptors(client);

const get = async <T>(
  url: string,
  params: Record<string, any> = {},
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse<T>> => {
  return client
    .get<T>(url, { params, ...config })
    .then((response: AxiosResponse<T>) => response);
};

const post = async <T>(
  url: string,
  data: Record<string, any> = {},
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse<T>> => {
  return client
    .post<T>(url, data, config)
    .then((response: AxiosResponse<T>) => response);
};

const put = async <T>(
  url: string,
  data: Record<string, any> = {},
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse<T>> => {
  return client
    .put<T>(url, data, config)
    .then((response: AxiosResponse<T>) => response);
};

const del = async <T>(
  url: string,
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse<T>> => {
  return client
    .delete<T>(url, config)
    .then((response: AxiosResponse<T>) => response);
};

export { get, post, put, del, client };
