import React, { useEffect } from "react";
import { BiSolidErrorCircle } from "react-icons/bi";
import { BsCheckCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@/redux/store";
import { hideSnackBar } from "@/redux/slices/snackBarSlice";

export default function SnackBar() {
  const dispatch = useDispatch<AppDispatch>();
  const { show, message, type } = useSelector((state: any) => state.snackBar);

  // Hide SnackBar after 4000ms or 4 sec 
  useEffect(() => {
    setTimeout(() => {
      dispatch(hideSnackBar());
    }, 4000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <section
      className={`w-fit max-w-md bg-smoky-black fixed z-[1000] bottom-6 left-5 right-5  mx-auto flex items-center justify-center px-4 py-2 rounded shadow-elevation-6 transition-all ease-in-out duration-200 ${show ? "translate-y-0 opacity-100" : "translate-y-[200px] opacity-0"
        }`}
    >
      <div className="min-w-[20px] w-fit">
        {type === "success" ? (
          <BsCheckCircleFill className="text-base text-[#2E7D32]" />
        ) : type === "error" ? (
          <BiSolidErrorCircle className="text-base text-primary-red" />
        ) : (
          <BiSolidErrorCircle className="text-base text-primary-red" />
        )}
      </div>
      <p className="text-sm font-gtAmerica font-normal text-white">{message}</p>
    </section>
  );
}
