import { createSlice } from "@reduxjs/toolkit";

interface AuthState {
  token: string | null;
  refreshToken: string | null;
  email: string | null;
  fullName: string | null;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | number | null;
  isAdmin: boolean;
  hasPasskeys: boolean;
  hasAccessToOrders: boolean;
}

const initialState: AuthState = {
  token: null,
  refreshToken: null,
  email: null,
  fullName: null,
  firstName: null,
  lastName: null,
  phoneNumber: null,
  isAdmin: false,
  hasPasskeys: false,
  hasAccessToOrders: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      const {
        token,
        refreshToken,
        email,
        fullName,
        firstName,
        lastName,
        phoneNumber,
        isAdmin,
        hasPasskeys,
        hasAccessToOrders,
      } = action.payload;
      state.token = token;
      state.refreshToken = refreshToken;
      state.email = email;
      state.fullName = fullName;
      state.firstName = firstName;
      state.lastName = lastName;
      state.phoneNumber = phoneNumber;
      state.isAdmin = isAdmin;
      state.hasPasskeys = hasPasskeys;
      state.hasAccessToOrders = hasAccessToOrders;
    },
    logout: (state) => {
      state.token = null;
      state.refreshToken = null;
      state.email = null;
      state.fullName = null;
      state.firstName = null;
      state.lastName = null;
      state.phoneNumber = null;
      state.isAdmin = false;
      state.hasPasskeys = false;
      state.hasAccessToOrders = false;
    },
    updateProfile: (state, action) => {
      const { email, fullName,  firstName, lastName, phoneNumber } =
        action?.payload?.data?.profile ?? {};
        const auth = action?.payload?.data?.auth ?? {};
      state.email = email ?? state.email;
      state.fullName = fullName ?? state.fullName;
      state.firstName = firstName ?? state.firstName;
      state.lastName = lastName ?? state.lastName;
      state.phoneNumber = phoneNumber ?? state.phoneNumber;
      state.token = auth?.token ?? state.token;
      state.refreshToken = auth?.refreshToken ?? state.refreshToken;
    },
  },
});

export const { login, logout, updateProfile } = authSlice.actions;

export default authSlice.reducer;


