import { isDebug } from "@/utils/getEnvironment";
import { useMemo, useRef } from "react";

export const LATLNG_UAE = {
  lat: 25.184672972293185,
  lng: 55.29787416989978,
};

export const baseURL = isDebug
  ? "https://dev-api.strabl.com"
  : "https://api.strabl.com";

export const defaultAPIErrorMessage = (errorMessageFromAPI: string) =>
  errorMessageFromAPI || "Something went wrong. ";

export const searchNotFoundError = (searchQuery: string) =>
  `No results found for "${searchQuery}".`;
export const paginationUrl = (
  pageName: string,
  page: number,
  pageSize: number,
  selectedSortOption?: { label: string; value: string },
  selectedFilterOption?: { label: string; value: string },
  searchQuery?: string
) => {
  return `/${pageName}?pageNumber=${page + 1}${
    selectedSortOption ? `&sort=${selectedSortOption.value}` : ""
  }&pageSize=${pageSize}${
    selectedFilterOption ? `&status=${selectedFilterOption.value}` : ""
  }${searchQuery ? `&search=${searchQuery}` : ""}`;
};
export const setPagination = ({
  isMobile,
  pageNumber,
  pageSize,
  paginationModel,
  setPaginationModel,
}: any) => {
  // Parse and set pagination only if it's different from the current state
  const parsedPageNumber = isMobile
    ? parseInt(pageNumber)
    : parseInt(pageNumber) - 1; // MUI is zero-indexed
  const parsedPageSize = parseInt(pageSize) || 10;

  if (
    pageNumber &&
    pageSize &&
    (paginationModel.page !== parsedPageNumber ||
      paginationModel.pageSize !== parsedPageSize)
  ) {
    setPaginationModel({
      page: parsedPageNumber,
      pageSize: parsedPageSize,
    });
  }
};
export const useRowCount = (totalRecords: any) => {
  const rowCountRef = useRef(totalRecords || 0);

  const rowCount = useMemo(() => {
    if (totalRecords !== undefined) {
      rowCountRef.current = totalRecords;
    }
    return rowCountRef.current;
  }, [totalRecords]);

  return rowCount;
};