import { PublicKeyCredentialRequestOptionsJSON } from "@github/webauthn-json/dist/types/basic/json";
import {
  create,
  get,
  parseCreationOptionsFromJSON,
  parseRequestOptionsFromJSON,
} from "@github/webauthn-json/browser-ponyfill";
import { supported } from "@github/webauthn-json";
import { getBrowserType, getOsName } from "@/utils/GetBrowserName";
import { getFingerprint } from "@thumbmarkjs/thumbmarkjs";
import {
  bioMetricLoginFinish,
  bioMetricLoginStart,
  bioMetricRegisterFinish,
  bioMetricRegisterstart,
  checkIfPasskeysExistsForEmailApi,
} from "@/network/services/authService";

interface AssertionStartResponse {
  assertionId: string;
  publicKeyCredentialRequestOptions: PublicKeyCredentialRequestOptionsJSON;
}

const checkPasskeysSupport = (): boolean => {
  return supported();
};

const BioMetricLogin = async (email: string) => {
  const response = await bioMetricLoginStart();
  const data = response.data.data;
  const auth = await handleAssertionStart(
    {
      assertionId: data.assertionId,
      publicKeyCredentialRequestOptions: data.publicKeyCredentialRequestOptions,
    },
    email
  );

  if (auth) {
    return auth;
  }
};

const BioMetricRegister = async () => {
  try {
    const response = await bioMetricRegisterstart({});

    if (response.data.data.status === "OK") {
      await createCredentials(response);
    } else {
      throw new Error(
        response?.data?.message || "Registration failed, Please try again"
      );
    }
  } catch (error: any) {
    throw error;
  }
};

const handleAssertionStart = async (
  response: AssertionStartResponse,
  userEmail: string
): Promise<any> => {
  removeNullProperties(response);
  const options = parseRequestOptionsFromJSON({
    publicKey: response.publicKeyCredentialRequestOptions,
  });
  const credential = await get(options);

  const assertionResponse = {
    assertionId: response.assertionId,
    credential: credential,
    email: userEmail,
  };

  const result = await bioMetricLoginFinish(assertionResponse);

  const data = await result.data;
  if (data && data.data) {
    return data.data;
  } else {
    throw new Error(data?.message || "Login failed, Please try again");
  }
};
// Error messages for Passkey
const abortErrorMessage =
  "Passkey registration/login cancelled, please try again or request OTP to proceed";
const networkErrorMessage =
  "Passkey registration/login Failed, please try again or request OTP to proceed";
const duplicatePasskkeyMessage =
  "Your passkey is already registered, please check your account for registered passkeys";

const createCredentials = async (responseData: any) => {
  try {
    const options: any = parseCreationOptionsFromJSON({
      publicKey: responseData.data.data.publicKey,
    });
    delete options.publicKey?.extensions?.appidExclude;

    options.publicKey.authenticatorSelection = {
      requireResidentKey: true,
      residentKey: "required",
    };

    const credential = await create(options);
    const FingerPrintHash = await getFingerprint(false);

    const credentialResponse = {
      registrationId: responseData.data.data.registrationId,
      credential,
      browserOs: `${getBrowserType()}/${getOsName()}`,
      browserFingerprint: FingerPrintHash,
    };

    const res = await bioMetricRegisterFinish(credentialResponse);

    if (res.data.code !== "2000") {
      throw new Error(
        res?.data?.message || "Registration failed, Please try again"
      );
    }
  } catch (error: any) {
    switch (error.name) {
      case "NotAllowedError":
      case "OptOutError":
      case "AbortError":
        throw new Error(abortErrorMessage);
      case "NetworkError":
        throw new Error(networkErrorMessage);
      case "InvalidStateError":
        throw new Error(duplicatePasskkeyMessage);
      default:
        throw error;
    }
  }
};

const checkIfPasskeysExistsForEmail = async (email: string) => {
  if (checkPasskeysSupport()) {
    // Check if passkeys exists for the email
    const FingerPrintHash = await getFingerprint(false);
    const res = await checkIfPasskeysExistsForEmailApi({
      email: email,
      fingerprint: FingerPrintHash,
    });

    if (res?.data?.data === true) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const removeNullProperties = (obj: any) => {
  for (let key in obj) {
    if (obj[key] === null) {
      delete obj[key];
    } else if (typeof obj[key] === "object") {
      removeNullProperties(obj[key]);
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    }
  }
};

export {
  BioMetricLogin,
  BioMetricRegister,
  checkPasskeysSupport,
  checkIfPasskeysExistsForEmail,
};
