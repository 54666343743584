import { createSlice } from "@reduxjs/toolkit";

interface sideBarState {
    isOpen: boolean,
}

const initialState: sideBarState = {
    isOpen: false
};

const sideBarSlice = createSlice({
    name: "sideBar",
    initialState,
    reducers: {
        toggleShowSideBar: (state, action) => {
            state.isOpen = action.payload.isOpen;
        },
    },
});

export const { toggleShowSideBar } = sideBarSlice.actions;

export default sideBarSlice.reducer;
