import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseURL } from "@/utils/constants";
import axios from "axios";

interface OrderState {
  orders: any[];
  order: {} | null;
  loading: boolean;
  error: string | null;
}

export const fetchOrders = createAsyncThunk(
  "order/fetchOrders",
  async (token: string, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${baseURL}/v2/admin/orders`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOrder = createAsyncThunk(
  "order/fetchOrder",
  async ({ token, id }: { token: string; id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${baseURL}/v2/admin/order/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState: OrderState = {
  orders: [],
  order: null,
  loading: false,
  error: null,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    clearOrders: (state) => {
      state.orders = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action?.payload?.data || null;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchOrder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.order = action?.payload?.data || [];
      })
      .addCase(fetchOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearOrders } = orderSlice.actions;

export default orderSlice.reducer;
