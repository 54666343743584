import { AxiosInstance, AxiosRequestConfig, AxiosError } from "axios";
import refreshAccessToken from "@/utils/refreshToken";
import { logout } from "@/redux/slices/authSlice";
import { store } from "@/redux/store";

export const setupInterceptors = (client: AxiosInstance) => {
  client.interceptors.request.use(
    (config: any) => {
      const state = store.getState();
      const accessToken = state.auth.token;

      if (config.delayed) {
        return new Promise<AxiosRequestConfig>((resolve) =>
          setTimeout(() => resolve(config), 500)
        );
      }

      const authUrls = ["/auth/login"];
      // Check if the request URL matches any in the authUrls array

      const isLoginUrl = authUrls.some((url) => config.url?.includes(url));
      if (isLoginUrl) {
        // Remove Authorization header if it exists

        delete config.headers?.Authorization;
      } else if (accessToken) {
        config.headers = config.headers || {};
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }

      return config;
    },
    (error: AxiosError) => Promise.reject(error)
  );

  client.interceptors.response.use(
    (response) => response,
    async (error: any) => {
      const originalRequest = error.config;
      const { data, status } = error?.response;

      // Check if Error is for Expired Auth Token then Refresh auth token and call same API again. If Refresh Token is expired then logout the user
      if (
        (data?.code === "US-045" || data?.code === "4012" || status === 401) &&
        !originalRequest?._retry
      ) {
        originalRequest._retry = true;
        const storeModule = import("@/redux/store");
        const { store } = await storeModule;

        if (store.getState().auth.refreshToken) {
          try {
            const newAccessToken = await refreshAccessToken(store);
            if (newAccessToken) {
              // Retry the original request with the new token
              originalRequest.headers[
                "Authorization"
              ] = `Bearer ${newAccessToken}`;
              return client(originalRequest);
            }
          } catch (error) {
            // Remove 'Authorization' header in case of an error
            delete client.defaults.headers.common["Authorization"];
            // Logout the user
            store.dispatch(logout());
          }
        } else {
          // Remove 'Authorization' header in case if refresh token is not available

          delete client.defaults.headers.common["Authorization"];
          // Logout the user

          store.dispatch(logout());
        }
      }

      return Promise.reject(error);
    }
  );
};
