import { logout, updateProfile } from "@/redux/slices/authSlice";
import axios from "axios";
import { baseURL } from "@/utils/constants";

let refreshTokenPromise: any = null;

const getClient = async () => {
  const { client } = await import("@/network/Config/apiService");
  return client;
};

const refreshAccessToken = async (store: any) => {
  // Check if a refresh token request is already ongoing, only one request should be made at a time
  if (!refreshTokenPromise) {
    refreshTokenPromise = (async () => {
      const authState = store.getState().auth;

      try {
        const response = await axios.get(`${baseURL}/auth/refresh-token`, {
          headers: {
            "X-Refresh-Token": authState.refreshToken,
          },
        });

        const newAccessToken = response?.data?.data?.auth?.token;
        const client = await getClient(); // Get the client instance dynamically

        // Update the access token in the Axios instance
        client.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${newAccessToken}`;

        store.dispatch(
          updateProfile({
            data: {
              auth: {
                token: newAccessToken,
                refreshToken: authState.refreshToken,
              },
            },
          })
        );

        // Resolve the ongoing refresh token promise
        refreshTokenPromise = null;
        return newAccessToken;
      } catch (error) {
        const client = await getClient(); // Get the client instance dynamically

        // Remove 'Authorization' header in case of an error
        delete client.defaults.headers.common["Authorization"];
        // Handle error, logout user
        store.dispatch(logout());

        // Reject the ongoing refresh token promise
        refreshTokenPromise = null;
        throw error;
      }
    })();
  }

  return refreshTokenPromise;
};

export default refreshAccessToken;
