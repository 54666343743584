import { createSlice } from "@reduxjs/toolkit";

interface SnackbarState {
    show: boolean,
    message: string,
    type: "success" | "error" | "",
}

const initialState: SnackbarState = {
    show: false,
    message: "",
    type: ""
};

const snackBarSlice = createSlice({
    name: "snackBar",
    initialState,
    reducers: {
        hideSnackBar: (state) => {
            state.show = false;
        },
        showSnackBar: (state, action) => {
            state.show = true;
            state.message = action.payload.message;
            state.type = action.payload.type;
        },
    },

});

export const { showSnackBar, hideSnackBar } = snackBarSlice.actions;

export default snackBarSlice.reducer;
