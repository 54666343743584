import { useState } from "react";
import { Button, Input } from "@strabl/ui";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "@/redux/slices/authSlice";
import { showSnackBar } from "@/redux/slices/snackBarSlice";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { jwtDecode } from "jwt-decode";
import { fetchUserProfileApi, loginApi } from "@/network/services/authService";
import {
  BioMetricLogin,
  checkIfPasskeysExistsForEmail,
} from "@/utils/PasskeysBioMetric";
import { emailRegex } from "@/utils/extras";

type JwtPayload = {
  roles: string[];
  exp: number;
  iat: number;
  sub: string;
};

const EmailLoginScreen: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
    loading: false,
  });
  const [showLoginWithPassword, setShowLoginWithPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userHasPasskeys, setUserHasPasskeys] = useState(false);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      continueLogin();
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    if (name === "email") {
      setShowLoginWithPassword(false);
    }
  };

  const fetchUserProfile = async (auth: any) => {
    const decodedToken = jwtDecode<JwtPayload>(auth?.token);

    if (decodedToken?.roles?.includes("ROLE_ADMIN")) {
      const res = await fetchUserProfileApi(auth?.token);
      const profile = res?.data?.data;
      const hasAccessToOrders =
        profile?.email?.toLowerCase() !== "colleague1.admin@strabl.com";

      dispatch(
        login({
          token: auth?.token,
          refreshToken: auth?.refreshToken,
          email: profile?.email,
          fullName: profile?.fullName,
          firstName: profile?.firstName,
          lastName: profile?.lastName,
          phoneNumber: profile?.phoneNumber,
          isAdmin: true,
          hasPasskeys: userHasPasskeys,
          hasAccessToOrders: hasAccessToOrders,
        })
      );
      dispatch(
        showSnackBar({
          message: "Login Successful",
          type: "success",
        })
      );
      navigate("/orders");
      setLoading(false);
    } else {
      setLoading(false);
      dispatch(
        showSnackBar({
          message: "User is not Admin",
          type: "error",
        })
      );
    }
  };

  const loginWithPasskey = async (email: string) => {
    try {
      setLoading(true);
      const auth = await BioMetricLogin(email);

      if (auth) {
        fetchUserProfile(auth?.auth);
      }
    } catch (error: any) {
      setShowLoginWithPassword(true);
      dispatch(
        showSnackBar({
          message:
            error?.response?.data?.message ||
            "Error while logging in with passkeys. Login with password",
          type: "error",
        })
      );
      setLoading(false);
    }
  };

  const loginWithPassword = async () => {
    if (values.email && values.password) {
      try {
        setLoading(true);
        const res = await loginApi({
          email: values.email,
          password: values.password,
        });

        const { auth } = res?.data?.data;
        fetchUserProfile(auth);
      } catch (error: any) {
        setLoading(false);
        dispatch(
          showSnackBar({
            message: error?.response?.data?.message || "Error logging in",
            type: "error",
          })
        );
      }
    } else {
      setLoading(false);
      dispatch(
        showSnackBar({
          message: "Email and Password are required",
          type: "error",
        })
      );
    }
  };

  const continueLogin = async () => {
    if (!emailRegex.test(values.email)) {
      dispatch(
        showSnackBar({
          message: "Invalid Email",
          type: "error",
        })
      );
      return;
    } else {
      try {
        setLoading(true);

        if (!showLoginWithPassword) {
          // Check if Email has Passkeys
          const hasPasskeys = await checkIfPasskeysExistsForEmail(values.email);
          setUserHasPasskeys(hasPasskeys);

          if (hasPasskeys) {
            // Login with Passkeys
            await loginWithPasskey(values.email);
          } else {
            // Show Password Field
            setShowLoginWithPassword(true);
            setLoading(false);
          }
        } else {
          // Login with Password
          await loginWithPassword();
        }
      } catch (error: any) {
        setLoading(false);
        dispatch(
          showSnackBar({
            message: error?.message || "Something went wrong",
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div
      className=" flex justify-center items-center w-full h-[100vh] bg-no-repeat relative before:absolute before:inset-0"
      style={{
        backgroundImage: "url(/Background.png)",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="flex h-screen w-screen justify-center items-center">
        <div className=" pw-fit flex flex-col  font-gtAmerica border-2 bg-white shadow-[6px_6px_0px_#000] py-12 px-10 rounded-[20px]">
          <div className="flex justify-center items-center mb-8">
            <img
              src="https://cdn.strabl.com/logo/1024x1024/1024x1024_logo1.png"
              width={220}
              height={65}
              className="block rounded-full object-cover w-[225px] h-[65px]"
              alt="Strabl logo"
            />
          </div>
          <div className="flex flex-col gap-y-3 w-[350px]">
            <Input
              variant="FloatingLabel"
              type="email"
              id="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onKeyDown={onKeyDown}
              onBlur={() => {}}
              label="Email"
            />
            {showLoginWithPassword && (
              <Input
                variant="FloatingLabel"
                type={showPassword ? "text" : "password"}
                hasPostFix={true}
                postFix={
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="text-sm"
                  >
                    {showPassword ? (
                      <AiOutlineEyeInvisible size={20} />
                    ) : (
                      <AiOutlineEye size={20} />
                    )}
                  </button>
                }
                id="email"
                name="password"
                value={values.password}
                onChange={handleChange}
                onKeyDown={onKeyDown}
                onBlur={() => {}}
                label="Password"
              />
            )}
          </div>
          <div className=" flex justify-center items-center mt-3">
            <Button
              color="SmokyBlack"
              buttonType="button"
              buttonText="Login"
              disabled={!values.email || loading}
              isLoading={loading}
              onContinue={continueLogin}
              className="z-20 w-full justify-center"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailLoginScreen;
