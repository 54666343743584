import React, { Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "@/pages/Login";
import { useSelector } from "react-redux";
import SnackBar from "@/components/SnackBar";
import loadingAnimation from "@/images/loadingAnimation.gif";

function App() {
  const { token, hasAccessToOrders } = useSelector((state: any) => state.auth);

  const Home = React.lazy(() => import("@/pages/Home"));
  const Disputes = React.lazy(() => import("@/pages/Disputes"));
  const DisputesDetails = React.lazy(() => import("@/pages/DisputesDetails"));
  const OrderDetails = React.lazy(() => import("@/pages/OrderDetails"));
  const TransactionReport = React.lazy(
    () => import("@/pages/TransactionReport")
  );
  const Merchants = React.lazy(() => import("@/pages/Merchants"));
  const MerchantsDetails = React.lazy(() => import("@/pages/MerchantsDetails"));
  const FeatureFlags = React.lazy(() => import("@/pages/FeatureFlags"));
  const FlagsManagement = React.lazy(() => import("@/pages/FlagsManagement"));
  const Agents = React.lazy(() => import("@/pages/Agents"));
  const AgentDetails = React.lazy(() => import("@/pages/AgentDetails"));
  const ProfileSettings = React.lazy(() => import("@/pages/ProfileSettings"));

  return (
    <div className="font-gtAmerica">
      <BrowserRouter>
        <Routes>
          <Route
            path="/login"
            element={
              token ? (
                <>
                  {hasAccessToOrders ? (
                    <Navigate to="/orders" />
                  ) : (
                    <Navigate to="/merchants" />
                  )}
                </>
              ) : (
                <Login />
              )
            }
          />

          <Route
            path="/orders"
            element={
              token ? (
                <SuspenseComponent>
                  {hasAccessToOrders ? <Home /> : <Navigate to="/merchants" />}
                </SuspenseComponent>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route
            path="/order/:id"
            element={
              token ? (
                <SuspenseComponent>
                  {hasAccessToOrders ? (
                    <OrderDetails />
                  ) : (
                    <Navigate to="/merchants" />
                  )}
                </SuspenseComponent>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route
            path="/transaction-report"
            element={
              token ? (
                <SuspenseComponent>
                  {hasAccessToOrders ? (
                    <TransactionReport />
                  ) : (
                    <Navigate to="/merchants" />
                  )}
                </SuspenseComponent>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route
            path="/"
            element={
              token ? (
                <SuspenseComponent>
                  {hasAccessToOrders ? <Home /> : <Navigate to="/merchants" />}
                </SuspenseComponent>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/disputes"
            element={
              token ? (
                <SuspenseComponent>
                  <Disputes />
                </SuspenseComponent>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/dispute/:id"
            element={
              token ? (
                <SuspenseComponent>
                  <DisputesDetails />
                </SuspenseComponent>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/merchants"
            element={
              token ? (
                <SuspenseComponent>
                  <Merchants />
                </SuspenseComponent>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/merchant/:id"
            element={
              token ? (
                <SuspenseComponent>
                  <MerchantsDetails />
                </SuspenseComponent>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/feature-flags"
            element={
              token ? (
                <SuspenseComponent>
                  <FeatureFlags />
                </SuspenseComponent>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/flags-management"
            element={
              token ? (
                <SuspenseComponent>
                  <FlagsManagement />
                </SuspenseComponent>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/agents"
            element={
              token ? (
                <SuspenseComponent>
                  <Agents />
                </SuspenseComponent>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/agent/:id"
            element={
              token ? (
                <SuspenseComponent>
                  <AgentDetails />
                </SuspenseComponent>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/profile-settings"
            element={
              token ? (
                <SuspenseComponent>
                  <ProfileSettings />
                </SuspenseComponent>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
        </Routes>
        <SnackBar />
      </BrowserRouter>
    </div>
  );
}
export default App;

function SuspenseComponent({ children }: { children: React.ReactNode }) {
  return (
    <Suspense
      fallback={
        <div className="flex flex-col justify-center items-center h-[80vh]">
          <img
            src={loadingAnimation}
            alt="Processing"
            className="w-28 h-28"
            width={694}
            height={694}
          />
        </div>
      }
    >
      {children}
    </Suspense>
  );
}
