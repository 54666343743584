export const maxLengths: { [key: string]: number } = {
  firstName: 30,
  lastName: 30,
  email: 60,
  contactNo: 15,
  storeName: 30,
  storeUrl: 100,
  domainUrl: 100,
  building: 60,
  address: 60,
  accountName: 60,
  bankName: 60,
  accountNumber: 60,
  iban: 40,
  adminAccessToken: 50,
  frontendAccessToken: 50,
  apikey: 50,
  secretKey: 50,
  storeId: 50,
};

export function isValidUrl(url: string) {
  const validUrlRegexPattern = new RegExp(
    "^(https?:\\/\\/|ftp:\\/\\/|file:\\/\\/|mailto:|tel:|ws:\\/\\/|wss:\\/\\/)?" + // Protocols including websocket, mailto, tel, etc.
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // Domain name
      "((\\d{1,3}\\.){3}\\d{1,3})|" + // OR IPv4 address
      "(\\[?[a-f\\d:]+\\]?))" + // OR IPv6 address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // Port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // Query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // Fragment locator
  return validUrlRegexPattern.test(url);
}

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const isLastThreeLine = (index: number, dataList: any, tableData: any) =>
  (dataList?.data.length > 3 && index >= dataList?.data.length - 3) ||
  (dataList?.data.length <= 3 && index > 0) ||
  (tableData?.data.length > 3 && index >= tableData?.data.length - 3) ||
  (tableData?.data.length <= 3 && index > 0);

// Format Price
export const formatPrice = (price: number) => {
  const formattedPrice = `${price.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;

  return `${formattedPrice}`;
};