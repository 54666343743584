
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "@/redux/store";

interface MerchantProfileState {
  data: any;
  loading: boolean;
  error: string | null;
}

const initialState: MerchantProfileState = {
  data: null,
  loading: false,
  error: null,
};

export const fetchMerchantProfile = createAsyncThunk(
  "merchantProfile/fetchMerchantProfile",
  async (id: string, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const { token } = state.auth;
    try {
      const response = await axios.get(`/v1/admin/user/merchants/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data?.message || "An error occurred. Please try again.");
    }
  }
);

const merchantProfileSlice = createSlice({
  name: "merchantProfile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMerchantProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMerchantProfile.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchMerchantProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default merchantProfileSlice.reducer;
