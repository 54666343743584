import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "@/redux/slices/authSlice";
import orderReducer from "@/redux/slices/orderSlice";
import snackBarReducer from "@/redux/slices/snackBarSlice";
import sideBarReducer from "@/redux/slices/sideBarSlice";
import merchantProfileReducer from "@/redux/slices/merchantProfileSlice";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import createWebStorage from "redux-persist/lib/storage/createWebStorage";

const createNoopStorage = () => {
  return {
    getItem(_key: any) {
      return Promise.resolve(null);
    },
    setItem(_key: any, value: any) {
      return Promise.resolve(value);
    },
    removeItem(_key: any) {
      return Promise.resolve();
    },
  };
};

const storage =
  typeof window !== "undefined"
    ? createWebStorage("local")
    : createNoopStorage();

const authPersistConfig = {
  key: "auth",
  version: 1,
  storage,
  whitelist: [
    "token",
    "refreshToken",
    "email",
    "fullName",
    "firstName",
    "lastName",
    "phoneNumber",
    "isAdmin",
    "hasPasskeys",
    "hasAccessToOrders",
  ],
};

const orderPersistConfig = {
  key: "order",
  version: 1,
  storage,
  whitelist: [],
};

const snackBarPersistConfig = {
  key: "snackBar",
  version: 1,
  storage,
  whitelist: [],
};

const sideBarPersistConfig = {
  key: "sideBar",
  version: 1,
  storage,
  whitelist: [],
};

const persistedReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  order: persistReducer(orderPersistConfig, orderReducer),
  snackBar: persistReducer(snackBarPersistConfig, snackBarReducer),
  sideBar: persistReducer(sideBarPersistConfig, sideBarReducer),
  merchantProfile: merchantProfileReducer,
});

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
