import { del, get, post } from "@/network/Config/apiService";

export const loginApi = async (data: any): Promise<any> => {
  return post<any>(`/auth/login`, data);
};
export const fetchUserProfileApi = async (authToken: string): Promise<any> => {
  return get<any>(
    `/v1/api/user/me`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
};

export const bioMetricLoginStart = async (): Promise<any> => {
  return post<any>(`/v2/auth/login/start`);
};
export const bioMetricLoginFinish = async (data: any): Promise<any> => {
  return post<any>(`/v2/auth/login/finish`, data);
};

export const bioMetricRegisterstart = async (data: any): Promise<any> => {
  return post<any>(`/v2/auth/register/start`, data);
};

export const bioMetricRegisterFinish = async (data: any): Promise<any> => {
  return post<any>(`/v2/auth/register/finish`, data);
};

export const checkIfPasskeysExistsForEmailApi = async (
  data: any
): Promise<any> => {
  return post<any>(`/v2/auth/identify`, data);
};

export const getUserPasskeys = async (): Promise<any> => {
  return get<any>(`/v1/api/passkey/`);
};

export const deleteUserPasskeys = async (passkeyId: string): Promise<any> => {
  return del<any>(`/v1/api/passkey/${passkeyId}`);
};

export const updatePasskeys = async (data: any): Promise<any> => {
  return post<any>(`/v1/api/passkey/`, data);
};